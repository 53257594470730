import React, { Fragment } from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import { Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import MetaDecorator from 'components/MetaDecorator/MetaDecorator';
import * as serviceWorker from 'serviceWorker';
import { setConfig } from 'cloudinary-build-url';

import AuthFetch from 'components/Auth/Auth';
import PropTypes from 'prop-types';
import ThemeProvider from 'components/ThemeProvider/ThemeProvider';
import Routes from 'components/Routes/Routes';
import ScrollHandler from 'components/ScrollHandler/ScrollHandler';
import ErrorBoundary from 'components/ErrorBoundary/ConnectedErrorBoundary';
import Layout from 'components/Layout/Layout';

import 'intersection-observer';
import FeatureBanner from './components/FeatureBanner/FeatureBanner';
import { PageFallback } from 'components/PageFallback/PageFallback';
import history from './browserHistory';

/**
 * Unstated
 */
import { AsyncFeatureToggleProvider } from 'unstated/FeatureToggles';
import AuthUnstated from 'unstated/Auth';
import UnstatedProviders from 'unstated/UnstatedProviders';
import { GlobalModals } from 'unstated/Modals';
import { GlobalNotification } from 'unstated/GlobalNotification';

import CreateAccountPopup from 'components/CreateAccountPopup/CreateAccountPopup';

const isProd = import.meta.env.VITE_APP_ENV === 'production';
const isLocal = process.env.NODE_ENV === 'development';
if (/prerender/gi.test(navigator.userAgent)) {
  setTimeout(() => {
    let style = document.createElement('STYLE');
    style.type = 'text/css';
    style.appendChild(
      document.createTextNode(
        [...document.querySelectorAll('[data-emotion]')]
          .flatMap(({ sheet }) =>
            [...sheet.cssRules].map((rules) => rules.cssText),
          )
          .join('\n'),
      ),
    );
    document.head.appendChild(style);
  }, 3000);
}

// Cloudinary
setConfig({
  cloudName: 'filmsupply',
});

/**
 * Axios Defaults
 */
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.timeout = 180000;

!!import.meta.env.VITE_APP_SENTRY_DSN &&
  !isLocal &&
  Sentry.init({
    // Docs & available ENVs here: https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/
    environment: import.meta.env.VITE_APP_ENV,
    debug: isLocal,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    release: import.meta.env.VITE_APP_CIRCLE_TAG,
    ignoreErrors: [
      /ResizeObserver/
    ],
    integrations: [
      new Sentry.BrowserTracing({
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation:
              Sentry.reactRouterV5Instrumentation(history),
          }),
          new Sentry.Replay(),
        ],
      }),
    ],

    // https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/sampling/#sampling-transaction-events
    tracesSampleRate: isProd ? 0.05 : 1.0,
    // https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/sampling/#sampling-transaction-events
    sampleRate: isProd ? 0.1 : 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

const LazyLoadInitScripts = () => {
  /**
   * This component lazy-loads the marketing-related scripts
   * in order to temporarily improve Lighthouse scores & SEO
   */
  const InitScriptsComponent = React.lazy(
    () => import('helpers/InitScriptsComponent'),
  );
  return (
    <>
      <React.Suspense fallback={null}>
        <InitScriptsComponent />
      </React.Suspense>
    </>
  );
};

class AppContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { lazyLoadInitScripts: null };
    this.loadInitScriptsTimer = null;
  }

  async componentDidMount() {
    // Campaign Tracking
    // If one of the whitelisted params are in
    // the url than pass all params to the API.
    const params = window.location.search;

    const campaignTrackingParamsWhitelist = [
      'gclid',
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
    ];
    if (
      campaignTrackingParamsWhitelist.some((param) => params.includes(param))
    ) {
      // campaignTracking(qs.parse(params));
    }
    // Lazy-load marketing scripts. Add any new tracking scritps to the LazyLoadInitScripts useEffect
    this.loadInitScriptsTimer = setTimeout(() => {
      this.setState({ lazyLoadInitScripts: <LazyLoadInitScripts /> });
      clearTimeout(this.loadInitScriptsTimer);
    }, 100);
  }

  render() {
    return (
      <div>
        {this.state.lazyLoadInitScripts}
        {this.props.children}
      </div>
    );
  }
}

AppContainer.propTypes = {
  children: PropTypes.node,
};

function App() {
  return (
    <AppContainer>
      <MetaDecorator />
      <UnstatedProviders>
        <Fragment>
          <AuthFetch />
          <ThemeProvider>
            <Router history={history}>
              <ErrorBoundary>
                <FeatureBanner />
                <CreateAccountPopup />
                <ScrollHandler />
                <GlobalModals />
                <GlobalNotification />
                <Layout>
                  <React.Suspense fallback={<PageFallback />}>
                    <Routes />
                  </React.Suspense>
                </Layout>
              </ErrorBoundary>
            </Router>
          </ThemeProvider>
        </Fragment>
      </UnstatedProviders>
    </AppContainer>
  );
}

const container = document.getElementById('root');
const root = createRoot(container);

(async () => {
  root.render(
    <AuthUnstated.Provider>
      <AsyncFeatureToggleProvider>
        <App />
      </AsyncFeatureToggleProvider>
    </AuthUnstated.Provider>,
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
